import revive_payload_client_wtauWnMWCj from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_e4o7THYuQS from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_sLFHwk1DCR from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_j5e3GYCWmR from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt-site-config@3.0.6_magicast@0.3.5_rollup@4.32.1_vite@6.0.11_@types+node@22.12.0_jit_8d43f63f01089dfdab2a5adeb371f3ce/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_R30gYFtqny from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_0ypzKEDd84 from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Fh8OK2Uqou from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_fCebrjpjh2 from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.32.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src3881362829/src/spilky-client/.nuxt/components.plugin.mjs";
import prefetch_client_El9e6fQww0 from "/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _01_api_GIiciWaEWX from "/codebuild/output/src3881362829/src/spilky-client/plugins/01.api.ts";
import _03_localization_gPzlazUODu from "/codebuild/output/src3881362829/src/spilky-client/plugins/03.localization.ts";
import _04_setup_5l0XcBq6CK from "/codebuild/output/src3881362829/src/spilky-client/plugins/04.setup.ts";
import _05_popupManager_peaaqOqHaJ from "/codebuild/output/src3881362829/src/spilky-client/plugins/05.popupManager.ts";
import _06_sentry_client_GkpL1Rt3Wt from "/codebuild/output/src3881362829/src/spilky-client/plugins/06.sentry.client.ts";
import _07_handleChunkError_client_aezU7vHS9y from "/codebuild/output/src3881362829/src/spilky-client/plugins/07.handleChunkError.client.ts";
import _09_hideLoader_client_14W30P2Ib8 from "/codebuild/output/src3881362829/src/spilky-client/plugins/09.hideLoader.client.ts";
export default [
  revive_payload_client_wtauWnMWCj,
  unhead_e4o7THYuQS,
  router_sLFHwk1DCR,
  _0_siteConfig_j5e3GYCWmR,
  payload_client_R30gYFtqny,
  navigation_repaint_client_0ypzKEDd84,
  check_outdated_build_client_Fh8OK2Uqou,
  plugin_vue3_fCebrjpjh2,
  components_plugin_KR1HBZs4kY,
  prefetch_client_El9e6fQww0,
  _01_api_GIiciWaEWX,
  _03_localization_gPzlazUODu,
  _04_setup_5l0XcBq6CK,
  _05_popupManager_peaaqOqHaJ,
  _06_sentry_client_GkpL1Rt3Wt,
  _07_handleChunkError_client_aezU7vHS9y,
  _09_hideLoader_client_14W30P2Ib8
]
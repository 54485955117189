import type { RouteLocationNamedRaw, RouteLocationNormalizedLoaded } from 'vue-router'
import type * as chatTypes from '~/ts/types/chat'
import type { KBArticle } from '~/ts/types/knowledge-base'
import type { AnyFn } from '~/ts/types/common'
import { ChatKindEnum, ChatMemberUserTypeEnum, ChatMessageTypeEnum, ChatStatusEnum } from '~/ts/enums/chat'
import { WSClientChannelEventEnum } from '~/ts/enums/ws'
import { TypingHandler } from '~/helpers/chat/TypingHandler'
import { MessageSendingQueue } from '~/helpers/chat/MessageSendingQueue'
import groupMessages from '~/helpers/chat/groupMessages'
import isMessageReadable from '~/helpers/chat/isMessageReadable'
import isOwnMessage from '~/helpers/chat/isOwnMessage'
import findFirstUnreadMessageId from '~/helpers/chat/findFirstUnreadMessageId'

export default (context: chatTypes.ChatStoreActionsContext) => {
    const {
        userStore,
        bus,
        lang,
        tab,
        windowSize,
        broadcastManager,
        refreshChatsOnTabActive,
        chats,
        currentCid,
        currentKind,
        currentRoute,
        currentChat,
        chatIds,
        chatsById,
        myChatList,
        newChatList,
        archiveChatList,
        archiveChatsPage,
        archiveChatListFilters,
        noMoreArchiveChats,
        cidPageRefreshKey,
        chatListRefreshKey,
        chatTypingContexts,
        operators,
        gifs,
        communicationChannels,
        knowledgeBaseItems,
        quickAnswers,
        quickAnswerTags,
        chatRouteChangedHook,
        chatTransferCanceledHook,
    } = context

    const initiated = useState<boolean>('chatStoreInitiated', () => false)
    const messageSendingQueue = new MessageSendingQueue()
    const chatTypingHandlers = new Map<chatTypes.Chat['id'], TypingHandler>()
    const transferChatTimers: Record<string, ReturnType<typeof useTimer>> = {}

    const isChatRoute = (route: RouteLocationNamedRaw | RouteLocationNormalizedLoaded): boolean => {
        return !!route?.name && String(route.name).startsWith('p-pid-chat')
    }

    const useTypingHandler = (id: chatTypes.Chat['id']): TypingHandler => {
        if (!chatTypingHandlers.has(id)) {
            chatTypingContexts.value[id] = {
                text: '',
                typing: false,
                entityType: ChatMemberUserTypeEnum.Operator,
                entityId: userStore.currentOperator.user_id,
            }

            chatTypingHandlers.set(id, new TypingHandler(chatTypingContexts.value[id]))
        }

        return chatTypingHandlers.get(id)
    }

    const removeTypingHandler = (id: chatTypes.Chat['id']): void => {
        chatTypingHandlers.delete(id)

        delete chatTypingContexts.value[id]
    }

    const handleHookNotification = (notification: Notification | undefined): void => {
        // Немає дозволу браузера
        if (!notification) {
            return
        }

        const offChatRouteChangedHook = chatRouteChangedHook.on((cid) => {
            if (cid === notification.data.chatId) {
                cleanUp()
            }
        })

        const offChatTransferCanceledHook = chatTransferCanceledHook.on((cid) => {
            if (cid === notification.data.chatId) {
                cleanUp()
            }
        })

        const cleanUp = () => {
            notification.close()

            offChatRouteChangedHook()
            offChatTransferCanceledHook()
        }

        notification.onclick = () => {
            cleanUp()

            window.focus()

            notification.close()

            const chat = chatsById.value[notification.data.chatId]

            if (!chat) {
                return
            }

            navigateTo({
                name: 'p-pid-chat-kind-cid',
                params: {
                    kind: getChatKind(chat),
                    cid: notification.data.chatId,
                },
            })
        }
    }

    const startChatConnection = (chat: chatTypes.Chat): void => {
        const chatChannel = broadcastManager.value.addChatChannel(chat.id)
        const chatClientChannel = broadcastManager.value.addChatClientChannel(chat.id)

        if (!chatChannel.channel.isSubscribed) {
            chatChannel.channel.subscribe()
        }

        if (!chatClientChannel.channel.isSubscribed) {
            chatClientChannel.channel.subscribe()
        }

        if (chatChannel.created) {
            chatChannel.events.onNewMessage((message: chatTypes.ChatMessage, done) => {
                const chatBodyEl = document.querySelector('[data-chat-conversation-body]')

                chat.numberOfUnreadMessages++

                if (!chatBodyEl) {
                    insertMessagesIntoChat(chat, [ message ], { position: 'after' })
                } else {
                    const { scrollHeight, scrollTop, clientHeight } = chatBodyEl
                    const scrollBottomBefore = scrollHeight - scrollTop - clientHeight

                    insertMessagesIntoChat(chat, [ message ], { position: 'after' })

                    if (scrollBottomBefore < CHAT_SCROLL_BOTTOM_THRESHOLD) {
                        nextTick(() => bus.emit(BUS_EVENT_CHAT_SCROLL_BODY))
                    }
                }

                done(handleHookNotification)

                chatListRefreshKey.value++
            })

            chatChannel.events.onMessageUpdate((message: chatTypes.ChatMessage, done) => {
                updateChatMessage(currentChat.value, message, message)

                done()
            })

            chatChannel.events.onMessageDelete((message: chatTypes.ChatMessage, done) => {
                removeChatMessage(currentChat.value, message)

                done()

                if (!message.viewed_at) {
                    chat.numberOfUnreadMessages--
                }

                chatListRefreshKey.value++
            })

            chatChannel.events.onMessageRead((message: chatTypes.ChatMessage, done) => {
                updateChatMessage(chat, message, message)

                done()
            })
        }

        if (chatClientChannel.created) {
            chatClientChannel.events.onTyping((context: chatTypes.ChatTypingContext, done) => {
                if (context.entityType === ChatMemberUserTypeEnum.Operator) {
                    return
                }

                const typingHandler = useTypingHandler(chat.id)

                if (context.typing) {
                    typingHandler.write(context.text)
                } else {
                    typingHandler.stop('visitor')
                }

                done()
            })
        }
    }

    const addChats = (items: chatTypes.Chat[]): void => {
        for (const item of items) {
            if (chatIds.value.includes(item.id)) {
                return
            }

            // Виправлення проблеми реактивності
            // Бо після додавання нового чату далі обʼєкт втрачає посилання та не реагує на зміни (наприклад додавання повідомлення у новий чат)
            const itemRef = ref(item)

            setChatExtraData(itemRef.value)

            chats.value.push(itemRef.value)

            if (import.meta.client) {
                startChatConnection(itemRef.value)
            }
        }
    }

    const removeChats = async (items: chatTypes.Chat[], _ensureCurrentCid = true): Promise<void> => {
        if (!items.length) {
            return
        }

        const idsToRemove = new Set(
            items.map(({ id }) => {
                broadcastManager.value.removeChatChannel(id, true)
                broadcastManager.value.removeChatClientChannel(id, true)

                removeTypingHandler(id)

                return id
            }),
        )

        chats.value = chats.value.filter(({ id }) => !idsToRemove.has(id))

        if (_ensureCurrentCid) {
            await ensureCurrentCid()
        }
    }

    const updateChat = (id: chatTypes.Chat['id'], chat: chatTypes.Chat, preserveMessages = false): void => {
        const existingChat = chatsById.value[id]

        if (preserveMessages) {
            chat.messages = existingChat.messages
        }

        setChatExtraData(chat)

        Object.assign(existingChat, chat)
    }

    const isChatListEmpty = (kind: ChatKindEnum): boolean => {
        switch (kind) {
            case ChatKindEnum.New:
                return !newChatList.value.length
            case ChatKindEnum.My:
                return !myChatList.value.length
            case ChatKindEnum.Archive:
                return !archiveChatList.value.length
        }
    }

    const buildChatListQuery = (kind: ChatKindEnum) => {
        if (kind !== ChatKindEnum.Archive) {
            return
        }

        const filter: Record<string, any> = {
            status: archiveChatListFilters.value.status === ChatStatusEnum.Closed
                ? [
                    ChatStatusEnum.Closed,
                    ChatStatusEnum.Missed,
                    ChatStatusEnum.Rejected,
                ]
                : [
                    ChatStatusEnum.Missed,
                    ChatStatusEnum.Rejected,
                ],
            createdAt: {},
        }

        if (archiveChatListFilters.value.dateFrom) {
            filter.createdAt['>='] = dateUtil
                .fromMillis(archiveChatListFilters.value.dateFrom)
                .startOf('day')
                .toFormat(DATE_DEFAULT_DATETIME_FORMAT)
        }

        if (archiveChatListFilters.value.dateTo) {
            filter.createdAt['<='] = dateUtil
                .fromMillis(archiveChatListFilters.value.dateTo)
                .endOf('day')
                .toFormat(DATE_DEFAULT_DATETIME_FORMAT)
        }

        if (archiveChatListFilters.value.channels.length) {
            filter.channel_type = archiveChatListFilters.value.channels
        }

        if (archiveChatListFilters.value.operators.length) {
            filter.operator_id = archiveChatListFilters.value.operators
        }

        return { filter }
    }

    const getChats = async (kinds: ChatKindEnum[], onlyEmpty = false): Promise<chatTypes.Chat[]> => {
        const responses = await Promise.all(
            kinds
                .filter(onlyEmpty ? isChatListEmpty : Boolean)
                .map((kind) => {
                    return useApi().chat.chats({
                        page: 1,
                        perPage: (kind === ChatKindEnum.Archive) ? CHAT_ARCHIVE_LIST_PER_PAGE : APP_MAX_PER_PAGE,
                        kind,
                        query: buildChatListQuery(kind),
                    })
                }),
        )

        const items = []

        responses.forEach(data => items.push(...data.items))

        return items
    }

    const fillChats = async (): Promise<void> => {
        addChats(
            await getChats([
                ChatKindEnum.New,
                ChatKindEnum.My,
                ChatKindEnum.Archive,
            ], true),
        )
    }

    const fillQuickAnswers = async (refresh = false): Promise<void> => {
        if (!refresh && quickAnswers.value.length) {
            return
        }

        const [ tagData, answerData ] = await Promise.all([
            useApi().quickAnswer.tagList(),
            useApi().quickAnswer.all(),
        ])

        quickAnswerTags.value = tagData
        quickAnswers.value = answerData.items
    }

    const fillKnowledgeBase = async (refresh = false): Promise<void> => {
        if (!refresh && knowledgeBaseItems.value.length) {
            return
        }

        const data = await useApi().knowledgeBase.tree({
            onlyPublished: 1,
            contentLanguage: lang.language.value,
        })

        knowledgeBaseItems.value = data.children
    }

    const fillOperators = async (refresh = false): Promise<void> => {
        if (!refresh && operators.value.length) {
            return
        }

        const data = await useApi().operator.all({ perPage: APP_MAX_PER_PAGE })

        operators.value = data.items
    }

    const fillGifs = async (): Promise<void> => {
        if (gifs.value.length) {
            return
        }

        gifs.value = await useApi().chat.gifFavorite()
    }

    const fillCommunicationChannels = async (refresh = false): Promise<void> => {
        if (!refresh && communicationChannels.value.length) {
            return
        }

        const data = await useApi().communicationChannel.all()

        communicationChannels.value = data.items
    }

    const ensureCurrentCid = async (
        preferredKind: ChatKindEnum = currentKind.value,
        forcedKind = false,
        disabled = windowSize.maxTablet.value,
    ): Promise<undefined | ReturnType<typeof navigateTo>> => {
        if (disabled) {
            return
        }

        if (!isChatRoute({ name: currentRoute.value.name }) || currentChat.value) {
            return
        }

        await nextTick() // Для коректної роботи (на випадок якщо цеей метод викликається після зміни чатів)

        const chatLists = forcedKind
            ? [
                [ preferredKind, getChatListByKind(preferredKind) ],
            ]
            : [
                [ ChatKindEnum.New, newChatList.value ],
                [ ChatKindEnum.My, myChatList.value ],
            ]

        chatLists.sort((a: any) => a[0] === preferredKind ? -1 : 1)

        for (const [ kind, list ] of chatLists) {
            if (!list.length) {
                continue
            }

            return navigateTo({
                name: 'p-pid-chat-kind-cid',
                params: {
                    kind: kind as ChatKindEnum,
                    cid: (list[0] as chatTypes.Chat).id,
                },
            }, { replace: true })
        }

        // Якщо чатів немає, але чат присутній в url
        // Чи потрібна інша вкладка списку чатів
        if (currentCid.value || (currentKind.value !== preferredKind)) {
            return navigateTo({
                name: 'p-pid-chat-kind',
                params: { kind: preferredKind },
            }, { replace: true })
        }
    }

    const removeCurrentCid = (): ReturnType<typeof navigateTo> => {
        return navigateTo({
            name: 'p-pid-chat-kind',
            params: { kind: currentKind.value },
        })
    }

    const sendMessage = (
        type: ChatMessageTypeEnum,
        value: {
            text?: string
            file?: File
            gif?: chatTypes.ChatGif
            article?: KBArticle
        },
        options: {
            isNote?: boolean
            callbackBeforeInsert?: AnyFn
        } = {},
    ): void => {
        if (!value) {
            return
        }

        if (value.text) {
            value.text = isString(value.text)
                ? value.text.trim()
                : String(value.text)
        }

        switch (type) {
            case ChatMessageTypeEnum.Image:
                if (!value.file) {
                    return
                }
                break
            case ChatMessageTypeEnum.Gif:
                if (!value.gif) {
                    return
                }
                break
            case ChatMessageTypeEnum.Article:
                if (!value.article) {
                    return
                }
                break
            default:
                if (!value.text) {
                    return
                }
        }

        const _currentChat = currentChat.value
        const sender = userStore.currentOperator
        const tempId = stringUtil.generateHash()

        const newMessage: chatTypes.ChatMessage = {
            sendingError: false,

            _id: tempId,
            native_id: undefined,
            is_note: Number(options.isNote),
            channel_id: _currentChat.channel_id,
            chat_id: _currentChat.id,
            sender_id: sender.id,
            sender_type: ChatMemberUserTypeEnum.Operator,
            senderEntity: sender,
            type,
            text: value.text,
            image_url: '',
            photo: undefined,
            article: undefined,
            edited_at: undefined,
            viewed_at: undefined,
            created_at: dateUtil.local().toUnixInteger(),
            updated_at: undefined,
        }

        const formData = new FormData()

        formData.append('sender_id', String(sender.id))

        if (options.isNote) {
            formData.append('is_note', '1')
        }

        let methodName: string

        switch (type) {
            case ChatMessageTypeEnum.Image:
                newMessage.photo = { temp: URL.createObjectURL(value.file) }

                if (value.text) {
                    formData.append('text', value.text)
                }

                formData.append('file', value.file)

                methodName = 'chatMessageSendImage'
                break
            case ChatMessageTypeEnum.Gif:
                newMessage.image_url = value.gif.url

                formData.append('gif_id', value.gif.gif_id)

                methodName = 'chatMessageSendGif'
                break
            case ChatMessageTypeEnum.Article:
                newMessage.article = value.article

                formData.append('kb_node_id', value.article._id)

                methodName = 'chatMessageSendArticle'
                break
            default:
                formData.append('text', value.text)

                methodName = 'chatMessageSendText'
        }

        options.callbackBeforeInsert?.()

        insertMessagesIntoChat(_currentChat, [ newMessage ], { position: 'after' })

        nextTick(() => bus.emit(BUS_EVENT_CHAT_SCROLL_BODY))

        chatListRefreshKey.value++

        messageSendingQueue.enqueue(async () => {
            let updatedMessage: chatTypes.ChatMessage

            try {
                const data = await useApi().chat[methodName]({
                    chatId: _currentChat.id,
                    data: formData,
                })

                updatedMessage = { ...newMessage, ...data }
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
            } catch (error) {
                updatedMessage = { ...newMessage, sendingError: true }
            }

            const messageIndex = _currentChat.messages.findIndex(v => v._id === newMessage._id)

            if ((messageIndex + 1) < _currentChat.messages.length) {
                // Перенесення повідомлення вниз, якщо воно було завантажено пізніше попередніх (наприклад, медіафайл)
                removeChatMessage(_currentChat, newMessage)

                insertMessagesIntoChat(_currentChat, [ updatedMessage ], { position: 'after' })
            } else {
                Object.assign(_currentChat.messages[messageIndex], updatedMessage)
            }

            appCache.delete(CACHE_CHAT_MESSAGES_PREFIX + tempId)
        })
    }

    const sendTyping = (chatId: chatTypes.Chat['id'], payload: chatTypes.ChatTypingContext): void => {
        broadcastManager.value?.sendChatClientChannelMessage(chatId, WSClientChannelEventEnum.Typing, payload)
    }

    const insertGroupedMessagesIntoChat = (
        chat: chatTypes.Chat,
        groupedMessages: chatTypes.ChatMessageDateGroup[],
        position: 'before' | 'after' = 'before',
    ): void => {
        if (!groupedMessages.length) {
            return
        }

        const existingIndex = position === 'before' ? 0 : -1
        const insertMethod = position === 'before' ? 'unshift' : 'push'

        const receivedIndex = position === 'before' ? -1 : 0
        const extractMethod = position === 'before' ? 'pop' : 'shift'

        const currentExistingDateGroup = chat.groupedMessages.at(existingIndex)

        if (
            currentExistingDateGroup
            && (currentExistingDateGroup.dayStartAt === groupedMessages.at(receivedIndex)?.dayStartAt)
        ) {
            const currentExistingSenderGroup = currentExistingDateGroup.senderGroups.at(existingIndex)
            const extractedReceivedDateGroup = groupedMessages[extractMethod]()

            if (
                currentExistingSenderGroup.senderKey
                === extractedReceivedDateGroup.senderGroups.at(receivedIndex).senderKey
            ) {
                const extractedSenderGroup = extractedReceivedDateGroup.senderGroups[extractMethod]()

                currentExistingSenderGroup.messages[insertMethod](...extractedSenderGroup.messages)
            }

            currentExistingDateGroup.senderGroups[insertMethod](...extractedReceivedDateGroup.senderGroups)
        }

        chat.groupedMessages[insertMethod](...groupedMessages)
    }

    const insertMessagesIntoChat = (
        chat: chatTypes.Chat,
        messages: chatTypes.ChatMessage[],
        options: {
            position?: 'before' | 'after'
            firstUnreadId?: string
            withGrouped?: boolean
        } = {},
    ): void => {
        const {
            position = 'before',
            firstUnreadId = '',
            withGrouped = true,
        } = options

        chat.messages[position === 'before' ? 'unshift' : 'push'](...messages)

        if (withGrouped) {
            insertGroupedMessagesIntoChat(chat, groupMessages(lang, messages, firstUnreadId), position)
        }
    }

    const setChatExtraData = (chat: chatTypes.Chat): chatTypes.Chat => {
        const unreadItemIndex = userStore.handshakeUnreadInCurrentProject.findIndex(v => v.chat_id === chat.id)

        if (unreadItemIndex === -1) {
            // Не 0, бо можливо це новий чат отриманий по сокетам
            chat.numberOfUnreadMessages = chat.messages.filter((message) => {
                return isMessageReadable(message) && !isOwnMessage(message, userStore.currentOperator)
            }).length
        } else {
            chat.numberOfUnreadMessages = userStore.handshakeUnreadInCurrentProject.splice(unreadItemIndex, 1)[0].unread
        }

        chat.noMoreMessages = false

        chat.groupedMessages = groupMessages(
            lang,
            chat.messages,
            findFirstUnreadMessageId(chat.messages, userStore.currentOperator, chat.noMoreMessages),
        )

        useTypingHandler(chat.id)

        return chat
    }

    const updateChatMessage = (
        chat: chatTypes.Chat,
        message: chatTypes.ChatMessage,
        updatedMessage: chatTypes.ChatMessage,
    ): void => {
        const messageIndex = chat.messages.findIndex(v => v._id === message._id)

        Object.assign(chat.messages[messageIndex], updatedMessage)

        ;(() => {
            for (const dateGroupIndex in chat.groupedMessages) {
                const dateGroup = chat.groupedMessages[dateGroupIndex]

                for (const senderGroupIndex in dateGroup.senderGroups) {
                    const senderGroup = dateGroup.senderGroups[senderGroupIndex]

                    const messageIndex = senderGroup.messages.findIndex(v => v._id === message._id)

                    if (messageIndex !== -1) {
                        Object.assign(senderGroup.messages[messageIndex], updatedMessage)

                        return
                    }
                }
            }
        })()

        appCache.delete(CACHE_CHAT_MESSAGES_PREFIX + message._id)
    }

    const removeChatMessage = (chat: chatTypes.Chat, message: chatTypes.ChatMessage): void => {
        const messageIndex = chat.messages.findIndex(v => v._id === message._id)

        chat.messages.splice(messageIndex, 1)

        ;(() => {
            for (const dateGroupIndex in chat.groupedMessages) {
                const dateGroup = chat.groupedMessages[dateGroupIndex]

                for (const senderGroupIndex in dateGroup.senderGroups) {
                    const senderGroup = dateGroup.senderGroups[senderGroupIndex]

                    const messageIndex = senderGroup.messages.findIndex(v => v._id === message._id)

                    if (messageIndex !== -1) {
                        senderGroup.messages.splice(messageIndex, 1)

                        if (!senderGroup.messages.length) {
                            dateGroup.senderGroups.splice(+senderGroupIndex, 1)
                        }

                        if (!dateGroup.senderGroups.length) {
                            chat.groupedMessages.splice(+dateGroupIndex, 1)
                        }

                        return
                    }
                }
            }
        })()

        appCache.delete(CACHE_CHAT_MESSAGES_PREFIX + message._id)
    }

    const submitEditableMessage = async (): Promise<void> => {
        const chat = currentChat.value

        if (!chat.editableMessage || chat.editableMessage.pendingForUpdate) {
            return
        }

        chat.editableMessage.pendingForUpdate = true

        try {
            const data = await useApi().chat.chatMessageUpdate({
                chatId: chat.id,
                messageId: chat.editableMessage._id,
                data: {
                    text: chat.editableMessage.editableText,
                },
            })

            updateChatMessage(chat, chat.editableMessage, data)

            chat.editableMessage = undefined
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
        } catch (error) {
            chat.editableMessage.pendingForUpdate = false
        }
    }

    const refreshArchivedChats = async (): Promise<void> => {
        await removeChats(chats.value.filter(v => CHAT_ARCHIVE_STATUSES.includes(v.status)), false)

        archiveChatsPage.value = 1
        noMoreArchiveChats.value = false

        addChats(await getChats([ ChatKindEnum.Archive ]))

        await ensureCurrentCid(ChatKindEnum.Archive, true)
    }

    const getChatKind = (chat: chatTypes.Chat): ChatKindEnum => ({
        [ChatStatusEnum.New]: ChatKindEnum.New,
        [ChatStatusEnum.Active]: ChatKindEnum.My,
        [ChatStatusEnum.Closed]: ChatKindEnum.Archive,
        [ChatStatusEnum.Missed]: ChatKindEnum.Archive,
        [ChatStatusEnum.Rejected]: ChatKindEnum.Archive,
    }[chat.status])

    const getChatListByKind = (kind: ChatKindEnum): chatTypes.Chat[] => ({
        [ChatKindEnum.New]: newChatList.value,
        [ChatKindEnum.My]: myChatList.value,
        [ChatKindEnum.Archive]: archiveChatList.value,
    }[kind])

    const init = async (): Promise<void> => {
        if (initiated.value) {
            return
        }

        initiated.value = true

        archiveChatListFilters.value.operators.push(userStore.currentOperator.id)

        await Promise.all([
            fillCommunicationChannels(),
            fillOperators(),
            fillChats(),
        ])
    }

    const refreshChats = async (): Promise<void> => {
        if (!tab.isTabActive.value) {
            refreshChatsOnTabActive.value = true

            // Відновлення підписки на старі чати
            chats.value.forEach(startChatConnection)

            return
        }

        refreshChatsOnTabActive.value = false
        archiveChatsPage.value = 1
        noMoreArchiveChats.value = false

        const newChats = await getChats([
            ChatKindEnum.New,
            ChatKindEnum.My,
            ChatKindEnum.Archive,
        ])

        const newChatIds = newChats.map(v => v.id)

        await removeChats(chats.value.filter(v => !newChatIds.includes(v.id)), false)

        newChats
            .forEach((chat) => {
                const existingChat = chatsById.value[chat.id]

                if (existingChat) {
                    existingChat.messages = chat.messages

                    removeTypingHandler(existingChat.id)
                    setChatExtraData(existingChat)
                    startChatConnection(existingChat)
                } else {
                    addChats([ chat ])
                }
            })

        await ensureCurrentCid()

        chatListRefreshKey.value++
        cidPageRefreshKey.value++
    }

    const clearTransferChatTimers = (chat: chatTypes.Chat): void => {
        if (!transferChatTimers[chat.id]) {
            return
        }

        transferChatTimers[chat.id].stop()

        delete transferChatTimers[chat.id]
    }

    const handleTransferredChats = (): void => {
        if (!import.meta.client) {
            return
        }

        for (const chat of chats.value) {
            if (chat.chatTransfer) {
                clearTransferChatTimers(chat)

                const milliseconds = Math.floor(
                    dateUtil
                        .rawFromSQL(chat.chatTransfer.expired_at, { zone: 'utc' })
                        .diffNow('millisecond')
                        .milliseconds,
                )

                transferChatTimers[chat.id] = useTimer(() => {
                    delete transferChatTimers[chat.id]

                    const wantedChat = chatsById.value[chat.id]

                    if (!wantedChat?.chatTransfer) { // Було видалено чи прийнято
                        return
                    }

                    if (wantedChat.chatTransfer.fromOperator.id === userStore.currentOperator.id) {
                        wantedChat.chatTransfer = undefined
                    } else {
                        removeChats([ wantedChat ])
                    }
                }, milliseconds, { immediate: true })
            }
        }
    }

    return {
        isChatRoute,
        useTypingHandler,
        removeTypingHandler,
        handleHookNotification,
        startChatConnection,
        addChats,
        removeChats,
        updateChat,
        isChatListEmpty,
        buildChatListQuery,
        getChats,
        fillChats,
        fillQuickAnswers,
        fillKnowledgeBase,
        fillOperators,
        fillGifs,
        fillCommunicationChannels,
        ensureCurrentCid,
        removeCurrentCid,
        sendMessage,
        sendTyping,
        insertGroupedMessagesIntoChat,
        insertMessagesIntoChat,
        setChatExtraData,
        updateChatMessage,
        removeChatMessage,
        submitEditableMessage,
        refreshArchivedChats,
        getChatKind,
        getChatListByKind,
        init,
        refreshChats,
        clearTransferChatTimers,
        handleTransferredChats,
    }
}


if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91token_93yvVe4ZBL3IMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue?macro=true";
import { default as _91token_93_46clientsLOhFFxRBKMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/codebuild/output/src3881362829/src/spilky-client/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.12.0_db0@0.2.3_eslint@9.19.0_jiti@2.4._1f76a9a134989677c9ed7e51f6c5d83a/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as _91token_93ccAj0Xfb67Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue?macro=true";
import { default as _91token_93_46clientjRUhw0m2RCMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue?macro=true";
import { default as google_46clientsZ2A8OEv9IMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue?macro=true";
import { default as _91token_93l36bT54VsBMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue?macro=true";
import { default as requestKrNLgcua3dMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue?macro=true";
import { default as sign_45inLQgCm5xHxgMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-in.vue?macro=true";
import { default as indexLS1AoK2louMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue?macro=true";
import { default as _1KCpHT6iH14Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue?macro=true";
import { default as _2qijLAUnCnGMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue?macro=true";
import { default as _32ECyhiBE3VMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue?macro=true";
import { default as _4yBIzpb7DQxMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue?macro=true";
import { default as stepxH2EAoVYqjMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue?macro=true";
import { default as widget_45simulator_45_91token_93_46clientQdLGgCjf3YMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/external/widget-simulator-[token].client.vue?macro=true";
import { default as indexnukRrOwPKKMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/index.vue?macro=true";
import { default as accountk4BXhHGgjOMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/account.vue?macro=true";
import { default as _91id_93ZdxBisFPLhMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue?macro=true";
import { default as billing4BovdIdJXIMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/billing.vue?macro=true";
import { default as _91cid_93TaHRA7fFMOMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue?macro=true";
import { default as _91kind_93KWxhiP7VL9Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/chat/[kind].vue?macro=true";
import { default as chatI0HYrMAb5YMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/chat.vue?macro=true";
import { default as inactive_45operatorAsYYIeL48vMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/inactive-operator.vue?macro=true";
import { default as knowledge_45basenTNyMd7SiPMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/knowledge-base.vue?macro=true";
import { default as accountRxO24O6jAMMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/account.vue?macro=true";
import { default as basic_45settingsaBgWnnI7mRMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue?macro=true";
import { default as operatorsZFSS9TAWX2Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue?macro=true";
import { default as basic_45settingsfxLgC7WR2gMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue?macro=true";
import { default as operatorstqxWhHLaY3Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue?macro=true";
import { default as basic_45settings0BIxBcDzUlMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue?macro=true";
import { default as code_45scriptT9k81fFOpOMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue?macro=true";
import { default as contacts7vMwzJIV8lMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue?macro=true";
import { default as operatorsioxbF5K9ThMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue?macro=true";
import { default as personalizationko06C0HY4fMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue?macro=true";
import { default as configureh1dA1XTMmSMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue?macro=true";
import { default as telegramgki0UKEf6VMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue?macro=true";
import { default as vibereq5kZsuWnvMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue?macro=true";
import { default as channelpEexJtRtGWMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel.vue?macro=true";
import { default as basic_45settingsrEzzFSYuq6Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public/basic-settings.vue?macro=true";
import { default as personalizationGWK8YIUKigMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public/personalization.vue?macro=true";
import { default as seo_45and_45analyticsjnKr1MZLTPMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public/seo-and-analytics.vue?macro=true";
import { default as publicqUiMDokGR4Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public.vue?macro=true";
import { default as knowledge_45baseMmNF8zi3ztMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base.vue?macro=true";
import { default as settingsR7Z78HENzzMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue?macro=true";
import { default as work_45analyticsKW3Lwpt1T8Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue?macro=true";
import { default as work_45schedulesaTGCkLa91Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue?macro=true";
import { default as editp2UTtGCBSpMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue?macro=true";
import { default as createjRMs3PweckMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/create.vue?macro=true";
import { default as operatorRV3gbaop5mMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator.vue?macro=true";
import { default as logonOI8pzcgANMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue?macro=true";
import { default as settingsnQeU1jbdwpMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue?macro=true";
import { default as editeSnxKjePYiMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue?macro=true";
import { default as createzDbao64oL6Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/create.vue?macro=true";
import { default as projectNPh9UiXuw4Meta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project.vue?macro=true";
import { default as editfwXUq3hEkvMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue?macro=true";
import { default as create80iY8qmsNNMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue?macro=true";
import { default as quick_45answerJjzhTCcoqzMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue?macro=true";
import { default as settingWkdCjv1qDRMeta } from "/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting.vue?macro=true";
export default [
  {
    name: "language-auth-change-password-token",
    path: "/:language?/auth/change-password/:token()",
    meta: _91token_93yvVe4ZBL3IMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/change-password/[token].vue")
  },
  {
    name: "language-auth-confirm-token",
    path: "/:language?/auth/confirm/:token()",
    meta: _91token_93_46clientsLOhFFxRBKMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/confirm/[token].client.vue"))
  },
  {
    name: "language-auth-create-password-token",
    path: "/:language?/auth/create-password/:token()",
    meta: _91token_93ccAj0Xfb67Meta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/create-password/[token].vue")
  },
  {
    name: "language-auth-invite-token",
    path: "/:language?/auth/invite/:token()",
    meta: _91token_93_46clientjRUhw0m2RCMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/invite/[token].client.vue"))
  },
  {
    name: "language-auth-oauth-google",
    path: "/:language?/auth/oauth/google",
    meta: google_46clientsZ2A8OEv9IMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/oauth/google.client.vue"))
  },
  {
    name: "language-auth-reset-password-token",
    path: "/:language?/auth/reset-password/:token()",
    meta: _91token_93l36bT54VsBMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/reset-password/[token].vue")
  },
  {
    name: "language-auth-reset-password-request",
    path: "/:language?/auth/reset-password/request",
    meta: requestKrNLgcua3dMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/reset-password/request.vue")
  },
  {
    name: "language-auth-sign-in",
    path: "/:language?/auth/sign-in",
    meta: sign_45inLQgCm5xHxgMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-in.vue")
  },
  {
    name: "language-auth-sign-up",
    path: "/:language?/auth/sign-up",
    meta: indexLS1AoK2louMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/index.vue")
  },
  {
    name: "language-auth-sign-up-step",
    path: "/:language?/auth/sign-up/step",
    meta: stepxH2EAoVYqjMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step.vue"),
    children: [
  {
    name: "language-auth-sign-up-step-1",
    path: "1",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/1.vue")
  },
  {
    name: "language-auth-sign-up-step-2",
    path: "2",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/2.vue")
  },
  {
    name: "language-auth-sign-up-step-3",
    path: "3",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/3.vue")
  },
  {
    name: "language-auth-sign-up-step-4",
    path: "4",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/[[language]]/auth/sign-up/step/4.vue")
  }
]
  },
  {
    name: "external-widget-simulator-token",
    path: "/external/widget-simulator-:token()",
    meta: widget_45simulator_45_91token_93_46clientQdLGgCjf3YMeta || {},
    component: () => createClientPage(() => import("/codebuild/output/src3881362829/src/spilky-client/pages/external/widget-simulator-[token].client.vue"))
  },
  {
    name: "index",
    path: "/",
    meta: indexnukRrOwPKKMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/index.vue")
  },
  {
    name: "p-pid-account",
    path: "/p/:pid()/account",
    meta: accountk4BXhHGgjOMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/account.vue")
  },
  {
    name: "p-pid-billing",
    path: "/p/:pid()/billing",
    meta: billing4BovdIdJXIMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/billing.vue"),
    children: [
  {
    name: "p-pid-billing-invoice-id",
    path: "invoice/:id()",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/billing/invoice/[id].vue")
  }
]
  },
  {
    name: "p-pid-chat",
    path: "/p/:pid()/chat",
    meta: chatI0HYrMAb5YMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/chat.vue"),
    children: [
  {
    name: "p-pid-chat-kind",
    path: ":kind()",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/chat/[kind].vue"),
    children: [
  {
    name: "p-pid-chat-kind-cid",
    path: ":cid()",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/chat/[kind]/[cid].vue")
  }
]
  }
]
  },
  {
    name: "p-pid-inactive-operator",
    path: "/p/:pid()/inactive-operator",
    meta: inactive_45operatorAsYYIeL48vMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/inactive-operator.vue")
  },
  {
    name: "p-pid-knowledge-base",
    path: "/p/:pid()/knowledge-base",
    meta: knowledge_45basenTNyMd7SiPMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/knowledge-base.vue")
  },
  {
    name: "p-pid-setting",
    path: "/p/:pid()/setting",
    meta: settingWkdCjv1qDRMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting.vue"),
    children: [
  {
    name: "p-pid-setting-account",
    path: "account",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/account.vue")
  },
  {
    name: "p-pid-setting-channel",
    path: "channel",
    meta: channelpEexJtRtGWMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure",
    path: ":id()/configure",
    meta: configureh1dA1XTMmSMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure.vue"),
    children: [
  {
    name: "p-pid-setting-channel-id-configure-telegram-basic-settings",
    path: "telegram/basic-settings",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-telegram-operators",
    path: "telegram/operators",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/telegram/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-basic-settings",
    path: "viber/basic-settings",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-viber-operators",
    path: "viber/operators",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/viber/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-basic-settings",
    path: "widget/basic-settings",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/basic-settings.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-code-script",
    path: "widget/code-script",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/code-script.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-contacts",
    path: "widget/contacts",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/contacts.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-operators",
    path: "widget/operators",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/operators.vue")
  },
  {
    name: "p-pid-setting-channel-id-configure-widget-personalization",
    path: "widget/personalization",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/[id]/configure/widget/personalization.vue")
  }
]
  },
  {
    name: "p-pid-setting-channel-connect-telegram",
    path: "connect/telegram",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/connect/telegram.vue")
  },
  {
    name: "p-pid-setting-channel-connect-viber",
    path: "connect/viber",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/channel/connect/viber.vue")
  }
]
  },
  {
    name: "p-pid-setting-knowledge-base",
    path: "knowledge-base",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base.vue"),
    children: [
  {
    name: "p-pid-setting-knowledge-base-public",
    path: "public",
    meta: publicqUiMDokGR4Meta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public.vue"),
    children: [
  {
    name: "p-pid-setting-knowledge-base-public-basic-settings",
    path: "basic-settings",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public/basic-settings.vue")
  },
  {
    name: "p-pid-setting-knowledge-base-public-personalization",
    path: "personalization",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public/personalization.vue")
  },
  {
    name: "p-pid-setting-knowledge-base-public-seo-and-analytics",
    path: "seo-and-analytics",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/knowledge-base/public/seo-and-analytics.vue")
  }
]
  }
]
  },
  {
    name: "p-pid-setting-operator",
    path: "operator",
    meta: operatorRV3gbaop5mMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit",
    path: ":id()/edit",
    meta: editp2UTtGCBSpMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-operator-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/settings.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-analytics",
    path: "work-analytics",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-analytics.vue")
  },
  {
    name: "p-pid-setting-operator-id-edit-work-schedule",
    path: "work-schedule",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/[id]/edit/work-schedule.vue")
  }
]
  },
  {
    name: "p-pid-setting-operator-create",
    path: "create",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/operator/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-project",
    path: "project",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit",
    path: ":id()/edit",
    meta: editeSnxKjePYiMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit.vue"),
    children: [
  {
    name: "p-pid-setting-project-id-edit-logo",
    path: "logo",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/logo.vue")
  },
  {
    name: "p-pid-setting-project-id-edit-settings",
    path: "settings",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/[id]/edit/settings.vue")
  }
]
  },
  {
    name: "p-pid-setting-project-create",
    path: "create",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/project/create.vue")
  }
]
  },
  {
    name: "p-pid-setting-quick-answer",
    path: "quick-answer",
    meta: quick_45answerJjzhTCcoqzMeta || {},
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/quick-answer.vue"),
    children: [
  {
    name: "p-pid-setting-quick-answer-id-edit",
    path: ":id()/edit",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/quick-answer/[id]/edit.vue")
  },
  {
    name: "p-pid-setting-quick-answer-create",
    path: "create",
    component: () => import("/codebuild/output/src3881362829/src/spilky-client/pages/p/[pid]/setting/quick-answer/create.vue")
  }
]
  }
]
  }
]